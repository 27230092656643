* {
  box-sizing: border-box;
}

body {
  width: 100vw;
  height: 100dvh;
}

#root {
  width: 100%;
  height: 100%;
}

